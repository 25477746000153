import './App.css';
import { Routes, Route } from "react-router-dom";
import React, { useEffect, Suspense } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";

import Loading from "./components/Loading/Loading";

// pages
import ProjectDetail from "./pages/ProjectDetail/ProjectDetail";

const LazyHome = React.lazy(() => import("./pages/Home"));
const LazyProjects = React.lazy(() => import("./pages/Projects/Projects"));

function App() {
  useEffect(() => {
    Aos.init({duration: 1000})
  }, [])

  return (
      <Routes>
        <Route path="/" element={<Suspense fallback={<Loading/>}><LazyHome/></Suspense>}/>
        <Route path="/projects" element={<Suspense fallback={<Loading/>}><LazyProjects/></Suspense>}/>
        <Route path="/projects/:id" element={<ProjectDetail/>}/> 
      </Routes> 
  );
}

export default App;
