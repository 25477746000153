import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import App from './App';
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <RecoilRoot>
          <App/>
      </RecoilRoot>
    </Router>
  </React.StrictMode>
);
