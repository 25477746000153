import "./Footer.css";
import logo from "../../logo.svg";

const Footer = () => {
    return (
        <footer className="container footer">
            <img src={logo} alt="logo" />
            <p>Designed by Caspy Creative</p>
            <p>All rights reserved ООО «Tekmil»</p>
        </footer>
    )
}

export default Footer;