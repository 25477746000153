import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { getProject } from "../../utils/api";
import { baseUrlState } from "../../utils/atoms/index";
import { useRecoilState } from "recoil";

// stylesheet
import "./ProjectDetail.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// components
import Navbar from "../../common/navbar/Navbar";
import Footer from "../../common/footer/Footer";

const ProjectDetail = () => {
    const { id } = useParams();
    const [ project, setProject ] = useState({});
    const [ baseUrl ] = useRecoilState(baseUrlState);

    useEffect(() => {
        const getData = async () => {
            const response = await getProject(id);
            setProject(response?.data)
        }
        getData();
    }, [id])

    return (
        <>
            <Navbar/>
            <div className="project-detail-container">
                <h1 className="heading p-heading">{project.title_highlight}</h1>
                { project?.project?.project_videos.length !== 0 ? 
                    <div className="video-player">
                        <video controls>
                        {
                            project?.project?.project_videos.map(data => {
                            return <source key={data.id} src={baseUrl + data.video} />
                            })
                        }
                        </video>
                    </div>
                    : null
                }
                
                
                
                <div className="project-description">
                    <p>{project.description}</p>
                </div>

            
                <div className="project-detail-images">
                    {
                        project?.project?.project_images.map(data => {
                            return (
                                <img key={data.id} src={baseUrl + data.image} alt="img"/>
                            )
                        })
                    }
                </div>
                
                <Link to="/projects" className="backBtn"><ArrowBackIcon/> yza dolan</Link>
            </div>

            <Footer/>
        </>
    )
}

export default ProjectDetail;