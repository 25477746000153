import "./Loading.css";

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="loading">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default Loading;