const data = [
    {
        id: 1,
        name: "about",
        path: "./#about"
    },
    {
        id: 2,
        name: "projects",
        path: "./#projects"
    },
    {
        id: 3,
        name: "services",
        path: "./#services"
    },
    {
        id: 4,
        name: "contact",
        path: "./#contact"
    }
]

export default data;