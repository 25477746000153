import axios from "axios";

const BASE_URL = "https://api.tekmil-tm.com"

const axiosInstance = axios.create({baseURL: BASE_URL});
const currentLang = localStorage.getItem('i18lngtekmil') || 'en';


const getProjects = () => {
    return axiosInstance.get(`/projects/all?lang=${currentLang}`)
}

const getProject = (project_id) => {
    return axiosInstance.get(`/projects/${project_id}`)
}

const getCarouselMain = () => {
    return axiosInstance.get("/carousel_main/all");
}

const getCarouselAbout = () => {
    return axiosInstance.get("/carousel_about/all");
}

const sendMessage = (form) => {
    return axiosInstance.post("/lets_talk/send-email", form)
}

export {
    getProjects,
    getProject,
    getCarouselMain,
    getCarouselAbout,
    sendMessage,
}