import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./Language.css";

const Language = () => {
    const [ open, setOpen ] = useState(false);
    const { i18n } = useTranslation();

    const handleClick = () => {
        var indicator = document.querySelector(".indicator");

        setOpen(!open)
        setTimeout(() => {
            document.querySelector("#lang2").classList.remove("hidden");
            indicator.style.width = "0px";
        }, 5);
    }

    return (
        <div className="nav-languages">
            <button className="language" type={"button"} onClick={handleClick}>
                {
                    i18n.language === "en" ? "EN" : "TM"
                }
            </button>
            {open && <DropDown state={{open, setOpen}}/>}
        </div>
    )
}

const DropDown = ({state}) => {
    const { i18n } = useTranslation();
    const { open, setOpen } = state;
    const DropDownItem = ( props ) => {
        return (
            <>
                {props.children}
            </>
        )
    }

    const changeLanguage = (e) => {
        const language = e.target.value
        i18n.changeLanguage(language);
        localStorage.setItem("i18lngtekmil", language);
        setOpen(!open);

        window.location.reload();
    }

    return (
        <div className="languageDropdown">
            <DropDownItem>
                <button 
                    className="languages hidden" 
                    id="lang2" 
                    value={i18n.language === "en" ? "tm" : "en"} 
                    onClick={changeLanguage}>
                    {
                        i18n.language === "en" ? "TM" : "EN"
                    }
                </button> 
            </DropDownItem>
        </div>
    )
}


export default Language;