import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../utils/hooks/useWindowDimensions";

// assets
import data from "./data";
import logo from "../../logo.svg";
import "./Navbar.css";

import Language from "../../components/Language/Language";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const nav = useNavigate();
    const { width } = useWindowDimensions();
    const [ showLinks, setShowLinks ] = useState(false);
    const { t } = useTranslation();
    const linksContainerRef = useRef(null);
    const linksRef = useRef(null);

    // Links Container
    useEffect(() => {
        const linksHeight = linksRef.current.getBoundingClientRect().height;
        if(showLinks){
            linksContainerRef.current.style.height = `${linksHeight + 30}px`;
        } else {
            linksContainerRef.current.style.height = "0px";
        }
    }, [showLinks])

    // Indicator
    useEffect(() => {
        var link = document.querySelectorAll(".link");
        var indicator = document.querySelector(".indicator");

        link.forEach(link => {
            link.addEventListener("click", e => {
                indicator.style.width = "45px";
                indicator.style.left = `${e.target.offsetLeft}px`
            })
        })
    }, [])

    return (
        <nav>
            <div className="nav-center">
                <div className="nav-header">
                    <img className={"logo"} src={logo} alt="logo" onClick={() => nav("/")}/>
                    <div className="nav-options">
                        { width < 900 && <Language/>}
                        <button 
                            onClick={() => setShowLinks(!showLinks)}
                            className={showLinks ? "nav-toggle active" : "nav-toggle"}>
                            <span></span>
                            <span></span>
                            <span></span>    
                        </button>
                    </div>                  
                </div>
                    <div className="links-container" ref={linksContainerRef}>
                        <ul className="links" ref={linksRef}>
                            {
                                data.map(link => {
                                    const { id, path, name } = link;
                                    return (
                                        <li key={id}>
                                            <a className="link" href={path}>{t(`navbar.${name}`)}</a>
                                        </li>
                                    )
                                })
                            }
                            <div className="indicator"></div>
                        </ul>
                        <div className="nav-address">
                            <a href="tel:+993611234556">+99361123456</a>
                            <a href="mailto:info@tekmil.com">info@tekmil.com</a>
                        </div>  
                    </div>
                { width >= 900 && <Language/>}
            </div>
        </nav>
    )
}

export default Navbar;